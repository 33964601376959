/**
 * Campaign model
 *
 * @param {object} origin
 * @param {BannerApp.Debug} Debug
 * @constructor
 */
BannerApp.Campaign = function(origin, Debug) {
    const _this = this;

    /**
     * PUBLIC PROPERTIES
     */
    _this.id         = null;
    _this.refId      = null;
    _this.name       = null;
    _this.debugName  = null;
    _this.start      = null;
    _this.end        = null;
    _this.startTime  = {};
    _this.endTime    = {};
    _this.days       = [];
    _this.audienceString   = null;
    _this.audience         = {};
    _this.isTestCampaign = true;

    /**
     * PUBLIC METHODS
     */
    this.canBeShown = canBeShown;
    this.isActive = isActive;
    this.getAudienceString = getAudienceString;
    this.setAudience = setAudience;

    _constructor(origin);

    /**
     * Constructor
     *
     * @param {object} origin
     * @private
     */
    function _constructor(origin) {
        _this.id    = origin.id;
        _this.refId = origin.externalId;
        _this.name  = origin.name;
        _this.debugName = `#${_this.id} "${_this.name}"`;
        _this.start = _UTC(origin.started_at);
        _this.end   = _UTC(origin.finished_at);
        _this.startTime = _time(origin.send_time, 'start');
        _this.endTime   = _time(origin.send_time, 'end');
        _this.days      = _days(origin.show_days);
        _this.audienceString = origin.audiences;

        try {
            _this.isTestCampaign = origin.additional_data
                ? !!JSON.parse(origin.additional_data).testCampaign
                : true;
        } catch (e) {
            _this.isTestCampaign = true;
        }

    }

    /**
     * PUBLIC METHODS
     */

    /**
     * Checks if campaign can be shown
     *
     * @param {BannerApp.UserFilters} userFilters
     * @param {boolean} isTestApp
     */
    function canBeShown(userFilters, isTestApp) {
        return (isTestApp || !_this.isTestCampaign)
            && isActive()
            && _isMatched(userFilters);
    }

    /**
     * Checks if campaign is active for current moment of time in local timezone
     *
     * @return {boolean}
     */
    function isActive() {
        if (!_isActiveDate()) {
            Debug.log(`Campaign ${_this.debugName}: date is not active`);
            return false;
        }
        if (!_isActiveTime()) {
            Debug.log(`Campaign ${_this.debugName}: local time is not active`);
            return false;
        }
        if (!_isActiveDay()) {
            Debug.log(`Campaign ${_this.debugName}: day is not active`);
            return false;
        }
        return true;
    }

    /**
     * Returns audiences encoded string
     *
     * @return {string}
     */
    function getAudienceString() {
        return _this.audienceString;
    }

    /**
     * Set audience object
     *
     * @param {BannerApp.Audience} audience
     * @return {BannerApp.Campaign}
     */
    function setAudience(audience) {
        _this.audience = audience;
        return _this;
    }

    /**
     * PRIVATE METHODS
     */

    /**
     * Checks if user filters are matching campaign audience
     *
     * @param {BannerApp.UserFilters} userFilters
     * @private
     */
    function _isMatched(userFilters) {
        if (!userFilters.fitsCampaignAudience(_this.audience)) {
            Debug.log(`Campaign ${_this.debugName}: user filters does not match the campaign's audience`);
            return false;
        }
        return true;
    }

    /**
     * Checks if campaign date is active in local timezone
     *
     * @return {boolean}
     * @private
     */
    function _isActiveDate() {
        const startTime = _this.start.getTime();
        const endTime = _this.end.getTime();
        const nowTime = new Date().getTime();
        return startTime <= nowTime && endTime >= nowTime;
    }

    /**
     * Checks if campaign time to show matches current time in local timezone
     *
     * @return {boolean}
     * @private
     */
    function _isActiveTime() {
        const now = new Date();
        const nowTime = now.getHours() * 100 + now.getMinutes();
        const startTime = _this.startTime.hours * 100 + _this.startTime.minutes;
        const endTime = _this.endTime.hours * 100 + _this.endTime.minutes;
        return startTime < nowTime && endTime > nowTime;
    }

    /**
     * Checks if campaign's days to show are matching today's day in local timezone
     *
     * @return {boolean}
     * @private
     */
    function _isActiveDay() {
        const nowDay = new Date().getDay();
        return _this.days.indexOf(nowDay) !== -1;
    }

    /**
     * Converting to UTC date
     *
     * @param string
     * @return {Date}
     * @private
     */
    function _UTC(string) {
        const dateLocal = parseDateString(string);
        const offset = dateLocal.getTimezoneOffset() * 60 * 1000;
        return new Date(dateLocal.getTime() - offset);
    }

    /**
     * Parse date string
     * @param {string} string
     * @return {Date}
     */
    function parseDateString(string) {
        const MONTH_INDEX = 1;
        let [date, time] = string.split(' ');
        date = date.split('-');

        // Months in JS start from 0
        date[MONTH_INDEX] = Number(date[MONTH_INDEX]) - 1;
        const dateParams = [...date, ...time.split(':')].map(param => Number(param));
        return new Date(...dateParams);
    }

    /**
     * Converting show time
     *
     * @param {string} string
     * @param {string} property
     * @return {{hours: number, minutes: number}}
     * @private
     */
    function _time(string, property) {
        const HOURS_INDEX = 0;
        const MINUTES_INDEX = 1;
        const object = JSON.parse(string);
        const timeArray = object[property]
            .split(':')
            .map(value => {
                return Number(value)
            });
        return {
            hours: timeArray[HOURS_INDEX],
            minutes: timeArray[MINUTES_INDEX],
        }
    }

    /**
     * Converting show days
     *
     * @param {string} string
     * @return {array}
     * @private
     */
    function _days(string) {
        const SUNDAY_CMS = 7;
        const SUNDAY_JS = 0;

        const ALL = [0, 1, 2, 3, 4, 5, 6];
        let array = JSON.parse(string);

        array = array.indexOf(9) !== -1
            ? ALL
            // change day type to int and replace sunday "7" with "0"
            : array.map(day => Number(day) === SUNDAY_CMS ? SUNDAY_JS : Number(day));

        return array.sort();
    }

};