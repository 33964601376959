/**
 * HTML elements factory module
 *
 * @param {string} domainName - a domain name with library resources
 * @param {BannerApp.Consts} CONSTANT
 * @constructor
 */
BannerApp.ElementsFactory = function(domainName, CONSTANT) {

    /**
     * Public properties
     */
    this.popup  = popup;
    this.div    = div;
    this.style  = style;
    this.iframe = iframe;

    /**
     * Returns a popup element
     *
     * @param {HTMLElement} innerContent
     * @param {object} options
     * @return {HTMLDivElement}
     */
    function popup(innerContent, options) {
        // create popup overlay and add event listener for popup close action
        const popupWrapper = div({
            class: CONSTANT.POPUP_WRAPPER_CLASS_NAME,
            tabindex: '-1',
            click: event => {
                return event.target.classList.contains(CONSTANT.POPUP_WRAPPER_CLASS_NAME)
                    && popupWrapper.parentNode.removeChild(popupWrapper);
            }
        });

        // create popup element
        const popup = div({
            class: 'trm-popup',
        });
        popup.appendChild(innerContent);
        popupWrapper.appendChild(popup);

        popupWrapper.appendChild(style(`
            .${CONSTANT.POPUP_WRAPPER_CLASS_NAME} {
                padding: 40px 10px;
                box-sizing: border-box;
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: url("${domainName}/banner-lib/img/spinner.gif") center center no-repeat rgba(0, 0, 0, 0.6);
                background-size: 5%;
                z-index: 999999;
                user-select: none;
            }

            .trm-popup {
                width: 100%;
                height: 100%;
                max-width: 400px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin: 0 auto;
                box-sizing: border-box;
                position: relative;
                text-align: center;
            }
            
            .trm-popup-close {
                position: absolute;
                top: 10px;
                right: 10px;
                color: #FFF;
                font-size: 50px;
                line-height: 18px;
                cursor: pointer;
            }
            
            .trm-popup-close:before {
                content: '×';
            }
            
            .trm-banner-popover {
                width: 90vw;
                height: 90vh;
                max-width: 400px;
                max-height: 500px;
                margin: 0 auto;
                box-sizing: border-box;
                position: relative;
            }
            
            .trm-banner-popover-dismiss {
                display: inline;
                cursor: pointer;
                color: #FFF;
                font-size: 13px;
                cursor: pointer;
                margin-top: 10px;
            }
        `));

        if (options.dismissUrl) {
            popup.appendChild(
                div({
                    class: 'trm-banner-popover-dismiss',
                    innerHTML: options.dismissUrl.text,
                    ariaLabel: options.dismissUrl.text,
                    tabindex: '0',
                    role: 'button',
                    click: options.dismissUrl.click
                }));
        }

        if (options.closeBtn) {
            popupWrapper.appendChild(
                div({
                    class: 'trm-popup-close',
                    ariaLabel: 'Close',
                    tabindex: '0',
                    role: 'button',
                    click: () => {
                        return popupWrapper.parentNode.removeChild(popupWrapper);
                    }
                }));
        }

        return popupWrapper;
    }

    /**
     * Div factory
     *
     * @param {object} options
     * @return {HTMLDivElement}
     */
    function div(options) {
        const div = document.createElement('div');

        if (options.class) {
            div.classList.add(options.class);
        }

        if (options.innerHTML) {
            div.innerHTML = options.innerHTML;
        }

        if (options.ariaLabel) {
            div.setAttribute('aria-label', options.ariaLabel);
        }

        if (options.title) {
            div.setAttribute('title', options.title);
        }

        if (options.tabindex) {
            div.setAttribute('tabindex', options.tabindex);
        }

        if (options.role) {
            div.setAttribute('role', options.role);
        }

        if (options.autofocus) {
            div.setAttribute('autofocus', '');
        }

        if (options.click) {
            div.addEventListener('click', event => {
                options.click(event);
            });
        }

        return div;
    }

    /**
     * Style factory
     *
     * @param {string} css
     * @return {HTMLStyleElement}
     */
    function style(css) {
        const style = document.createElement('style');
        style.type = 'text/css';
        style.appendChild(document.createTextNode(css));
        return style;
    }

    /**
     * Iframe factory
     *
     * @param {object} options
     * @return {HTMLIFrameElement}
     */
    function iframe(options) {
        const iframe = document.createElement('iframe');
        iframe.setAttribute('frameborder', '0');
        iframe.setAttribute('allowtransparency', 'true');
        iframe.style.backgroundColor = "transparent";

        // IE Fix
        iframe.style.display = 'none';

        if (options.url) {
            iframe.src = options.url;
        }

        if (options.class) {
            iframe.classList.add(options.class);
        }

        if (options.width) {
            iframe.setAttribute('width', options.width.toString());
        }

        if (options.height) {
            iframe.setAttribute('height', options.height.toString());
        }

        if (options.style) {
            Object.keys(options.style).map(style => {
                iframe.style[style] = options.style[style];
            });
        }

        iframe.addEventListener('load', () => {
            if (options.document) {
                const iframeDoc = iframe.contentWindow.document;

                // replace iframe document with banner
                iframeDoc.replaceChild(
                    iframeDoc.importNode(options.document.documentElement, true),
                    iframeDoc.documentElement
                );
            }

            if (options.onload) {
                options.onload(iframe);
            }

            if (options.onRendered) {
                const interval = setInterval(function () {
                    if (iframe.clientWidth && iframe.clientHeight) {
                        options.onRendered({
                            width: iframe.clientWidth,
                            height: iframe.clientHeight,
                        });
                        clearInterval(interval);
                    }
                }, 50);
            }

            // IE Fix
            setTimeout(() => {
                iframe.style.display = 'block';
            }, 100);

        }, false);

        return iframe;
    }
};