/**
 * This module is checking presentation settings and comeback options
 *
 * @constructor
 */
BannerApp.PresentationChecker = function(Storage) {
    /**
     * Constants
     */
    const STATES = {
        PRESENTATION: 1,
        EXPIRED: 2,
        CLICKED: 3,
        DISMISSED: 4,
    };

    const PRESENTATION_OPTIONS = {
        EVERY_TIME: 0,
        ONLY_N_TIMES: 1,
        EVERY_N_TIME: 3,
        TIME_CONDITIONS: 4,
    };

    const COMEBACK_OPTIONS = {
        INSTANTLY: 0,
        NEVER: 1,
        AFTER_X_TIMES: 2,
        TIME_CONDITIONS: 3,
    };

    const STORAGE_KEYS = {
        PRESENTATION_COUNTERS: 'presentation_counters',
        COMEBACK_COUNTERS: 'comeback_counters',
        CURRENT_STATE: 'banner_states',
        FIRST_SHOWN: 'first_shown',
        END_OF_PRESENTATION_PERIOD: 'end_of_presentation_period',
        END_OF_COMEBACK_PERIOD: 'end_of_comeback_period',
    };

    /**
     * Public methods
     */
    this.updateCounters = updateCounters;
    this.updateState = updateState;
    this.makeClicked = makeClicked;
    this.makeDismissed = makeDismissed;
    this.inPresentationState = inPresentationState;

    /**
     * Check if banner could be shown for user
     *
     * @param {object} bannerObject
     * @return {boolean}
     */
    function inPresentationState(bannerObject) {
        const bannerId = bannerObject.externalId;
        return _getState(bannerId) === STATES.PRESENTATION;
    }

    /**
     * Updates banner's counters when in presentation or comeback state
     *
     * @param {object} bannerObject
     */
    function updateCounters(bannerObject) {
        const bannerId = bannerObject.externalId,
              currentState = _getState(bannerId);

        switch (currentState) {
            case STATES.PRESENTATION:
            case STATES.EXPIRED:
                const presentationCase = Number(bannerObject.presentationOptions.case);
                _incrementPresentationCounter(bannerId);

                // For "Time conditioned" presentation option:
                // setting new period of presentation if the old one expires and resetting presentation counter
                if (presentationCase === PRESENTATION_OPTIONS.TIME_CONDITIONS) {
                    const isUpdatedPresentationPeriod = _updatePresentationPeriod(bannerObject);
                    if (isUpdatedPresentationPeriod) {
                        _setPresentationCounter(bannerId, 1);
                    }
                }
                break;
            case STATES.CLICKED:
                const comeBackCase = Number(bannerObject.comebackOptions.case);
                _incrementComebackCounter(bannerId);

                if (comeBackCase === COMEBACK_OPTIONS.TIME_CONDITIONS) {
                    const isUpdatedComebackPeriod = _updateComebackPeriod(bannerObject);
                    if (isUpdatedComebackPeriod) {
                        _setComebackCounter(bannerId, 1);
                    }
                }
                break;
        }
    }

    /**
     * Check state conditions and update the state if needed
     *
     * @param {object} bannerObject
     */
    function updateState(bannerObject) {
        const bannerId = bannerObject.externalId,
              currentState = _getState(bannerId);

        if (currentState === STATES.PRESENTATION && _hasExpired(bannerObject)) {
            _setState(bannerId, STATES.EXPIRED);
        }

        if (currentState === STATES.EXPIRED && _canBeShownAgain(bannerObject)) {
            _setState(bannerId, STATES.PRESENTATION);
        }

        if (currentState === STATES.CLICKED && _canComeBack(bannerObject)) {
            _setState(bannerId, STATES.PRESENTATION);
        }
    }

    /**
     * Check if banner can come back after click
     *
     * @param bannerObject
     * @private
     */
    function _canComeBack(bannerObject) {
        const bannerId = bannerObject.externalId,
              comeBackCase = Number(bannerObject.comebackOptions.case),
              currentComebacksCount = _getComebacksCount(bannerId),
              amountOfTimes = bannerObject.comebackOptions.ntimes;

        switch (comeBackCase) {
            case COMEBACK_OPTIONS.INSTANTLY:
                return true;
            case COMEBACK_OPTIONS.NEVER:
                return false;
            case COMEBACK_OPTIONS.AFTER_X_TIMES:
            case COMEBACK_OPTIONS.TIME_CONDITIONS:
                return currentComebacksCount > amountOfTimes;
        }
        return false;
    }

    /**
     * Check if banner presentation was expired
     *
     * @param {object} bannerObject
     * @return {boolean}
     * @private
     */
    function _hasExpired(bannerObject) {
        const bannerId = bannerObject.externalId,
              presentationCase = Number(bannerObject.presentationOptions.case),
              currentPresentationsCount = _getPresentationsCount(bannerId),
              amountOfTimes = bannerObject.presentationOptions.ntimes;

        switch (presentationCase) {
            case PRESENTATION_OPTIONS.ONLY_N_TIMES:
            case PRESENTATION_OPTIONS.TIME_CONDITIONS:
                return currentPresentationsCount > amountOfTimes;
            case PRESENTATION_OPTIONS.EVERY_N_TIME:
                return currentPresentationsCount % amountOfTimes !== 1;
        }
        return false;
    }

    /**
     * Check if banner can be shown again
     *
     * @param {object} bannerObject
     * @return {boolean}
     * @private
     */
    function _canBeShownAgain(bannerObject) {
        const bannerId = bannerObject.externalId,
              presentationCase = Number(bannerObject.presentationOptions.case),
              currentPresentationsCount = _getPresentationsCount(bannerId),
              amountOfTimes = bannerObject.presentationOptions.ntimes;

        switch (presentationCase) {
            case PRESENTATION_OPTIONS.EVERY_N_TIME:
                // show 1-st time, and next show will be on n-th + 1 time
                return currentPresentationsCount % amountOfTimes === 1;
            case PRESENTATION_OPTIONS.TIME_CONDITIONS:
                return currentPresentationsCount < amountOfTimes;
        }
    }

    /**
     * Update presentation period for banners if old period expired
     *
     * @param {object} bannerObject
     */
    function _updatePresentationPeriod(bannerObject) {
        const bannerId            = bannerObject.externalId,
              presentationOptions = bannerObject.presentationOptions.every.toString(),
              oldPeriodEndDate = _getEndPresentationPeriod(bannerId),
              newPeriodEndDate = _generateNewPeriodDate(oldPeriodEndDate, presentationOptions);

        if (_areEqualDates(oldPeriodEndDate, newPeriodEndDate)) {
            return false;
        }

        _setEndPresentationPeriod(bannerId, newPeriodEndDate);
        return true;
    }

    /**
     * Update comeback period for banners if old period expired
     *
     * @param {object} bannerObject
     */
    function _updateComebackPeriod(bannerObject) {
        const bannerId        = bannerObject.externalId,
              comeBackOptions = bannerObject.comebackOptions.after.toString(),
              oldPeriodEndDate = _getEndComebackPeriod(bannerId),
              newPeriodEndDate = _generateNewPeriodDate(oldPeriodEndDate, comeBackOptions);

        if (_areEqualDates(oldPeriodEndDate, newPeriodEndDate)) {
            return false;
        }

        _setEndComebackPeriod(bannerId, newPeriodEndDate);
        return true;
    }

    /**
     * Generates period ending date
     *
     * @param {Date} oldPeriodEndDate
     * @param {string} presentationOptions
     * @return {Date}
     * @private
     */
    function _generateNewPeriodDate(oldPeriodEndDate, presentationOptions) {
        const dateNow = new Date(),
              newPeriodEndDate = new Date();

        if (!_isValidDate(oldPeriodEndDate)) {
            oldPeriodEndDate = new Date();
        }

        switch (presentationOptions) {
            case 'THIS_MINUTE':
                newPeriodEndDate.setMinutes(dateNow.getMinutes() + 1);
                newPeriodEndDate.setSeconds(0);
                break;
            case 'THIS_HOUR':
                newPeriodEndDate.setHours(dateNow.getHours() + 1);
                newPeriodEndDate.setMinutes(0);
                newPeriodEndDate.setSeconds(0);
                break;
            case 'TODAY':
                newPeriodEndDate.setDate(dateNow.getDate() + 1);
                newPeriodEndDate.setHours(0);
                newPeriodEndDate.setMinutes(0);
                newPeriodEndDate.setSeconds(0);
                break;
            case 'THIS_WEEK':
                const daysToNextMonday = (1 + 7 - dateNow.getDay()) % 7;
                newPeriodEndDate.setDate(dateNow.getDate() + daysToNextMonday);
                newPeriodEndDate.setHours(0);
                newPeriodEndDate.setMinutes(0);
                newPeriodEndDate.setSeconds(0);
                break;
            case 'THIS_MONTH':
                newPeriodEndDate.setMonth(dateNow.getMonth() + 1);
                newPeriodEndDate.setDate(0);
                newPeriodEndDate.setHours(0);
                newPeriodEndDate.setMinutes(0);
                newPeriodEndDate.setSeconds(0);
                break;
            case 'MINUTE':
                newPeriodEndDate.setMinutes(dateNow.getMinutes() + 1);
                newPeriodEndDate.setSeconds(oldPeriodEndDate.getSeconds());
                break;
            case 'HOUR':
                newPeriodEndDate.setHours(dateNow.getHours() + 1);
                newPeriodEndDate.setMinutes(oldPeriodEndDate.getMinutes());
                newPeriodEndDate.setSeconds(oldPeriodEndDate.getSeconds());
                break;
            case 'DAY':
                newPeriodEndDate.setDate(dateNow.getDate() + 1);
                newPeriodEndDate.setHours(oldPeriodEndDate.getHours());
                newPeriodEndDate.setMinutes(oldPeriodEndDate.getMinutes());
                newPeriodEndDate.setSeconds(oldPeriodEndDate.getSeconds());
                break;
            case 'WEEK':
                newPeriodEndDate.setDate(dateNow.getDate() + 7);
                newPeriodEndDate.setHours(oldPeriodEndDate.getHours());
                newPeriodEndDate.setMinutes(oldPeriodEndDate.getMinutes());
                newPeriodEndDate.setSeconds(oldPeriodEndDate.getSeconds());
                break;
            case 'MONTH':
                newPeriodEndDate.setMonth(dateNow.getMonth() + 1);
                newPeriodEndDate.setDate(oldPeriodEndDate.getDate());
                newPeriodEndDate.setHours(oldPeriodEndDate.getHours());
                newPeriodEndDate.setMinutes(oldPeriodEndDate.getMinutes());
                newPeriodEndDate.setSeconds(oldPeriodEndDate.getSeconds());
                break;
            default:
                throw new Error(`Bad presentation option ${presentationOptions}`);
        }
        return newPeriodEndDate;
    }

    /**
     * Changes banner's state to "clicked"
     *
     * @param {number} bannerId
     */
    function makeClicked(bannerId) {
        _setState(bannerId, STATES.CLICKED);
        _setPresentationCounter(bannerId, 1);
        _setComebackCounter(bannerId, 0);
    }

    /**
     * Changes banner's state to "dismissed"
     *
     * @param {number} bannerId
     */
    function makeDismissed(bannerId) {
        _setState(bannerId, STATES.DISMISSED);
    }

    /**
     * Getting banners current state
     *
     * @param {number} bannerId
     * @private
     */
    function _getState(bannerId) {
        const state = Storage.get(STORAGE_KEYS.CURRENT_STATE, bannerId);
        return state ? state : _setState(bannerId, STATES.PRESENTATION);
    }

    /**
     * Getting banners current state
     *
     * @param {number} bannerId
     * @param {number} stateId
     * @private
     */
    function _setState(bannerId, stateId) {
        return Storage.setKey(STORAGE_KEYS.CURRENT_STATE, bannerId, stateId);
    }

    /**
     * Returns number of presentations for the banner
     *
     * @param {number} bannerId
     * @return {number}
     * @private
     */
    function _getPresentationsCount(bannerId) {
        return Storage.get(STORAGE_KEYS.PRESENTATION_COUNTERS, bannerId) || 0;
    }

    /**
     * Returns number of comebacks for the banner
     *
     * @param {number} bannerId
     * @return {number}
     * @private
     */
    function _getComebacksCount(bannerId) {
        return Storage.get(STORAGE_KEYS.COMEBACK_COUNTERS, bannerId) || 0;
    }

    /**
     * Increments presentation counter for specific banner
     *
     * @param {number} bannerId
     * @return {number} - new value
     * @private
     */
    function _incrementPresentationCounter(bannerId) {
        const currentValue = _getPresentationsCount(bannerId);
        return Storage.setKey(STORAGE_KEYS.PRESENTATION_COUNTERS, bannerId, currentValue + 1);
    }

    /**
     * Increments comeback counter for specific banner
     *
     * @param {number} bannerId
     * @return {number} - new value
     * @private
     */
    function _incrementComebackCounter(bannerId) {
        const currentValue = _getComebacksCount(bannerId);
        return Storage.setKey(STORAGE_KEYS.COMEBACK_COUNTERS, bannerId, currentValue + 1);
    }

    /**
     * Sets presentation counter
     *
     * @param {number} bannerId
     * @param {number} value
     * @return {number}
     * @private
     */
    function _setPresentationCounter(bannerId, value) {
        return Storage.setKey(STORAGE_KEYS.PRESENTATION_COUNTERS, bannerId, value);
    }

    /**
     * Sets comeback counter
     *
     * @param {number} bannerId
     * @param {number} value
     * @return {number}
     * @private
     */
    function _setComebackCounter(bannerId, value) {
        return Storage.setKey(STORAGE_KEYS.COMEBACK_COUNTERS, bannerId, value);
    }

    /**
     * Checks if data object is valid
     *
     * @param {*} date
     * @return {boolean}
     * @private
     */
    function _isValidDate(date) {
        return date instanceof Date && !isNaN(date);
    }

    /**
     * Sets end of presentation period date for banner
     *
     * @param {number} bannerId
     * @param {Date} date
     * @private
     */
    function _setEndPresentationPeriod(bannerId, date) {
        return Storage.setKey(STORAGE_KEYS.END_OF_PRESENTATION_PERIOD, bannerId, date.toJSON());
    }

    /**
     * Get end of presentation period date
     *
     * @param {number} bannerId
     */
    function _getEndPresentationPeriod(bannerId) {
        return new Date(Storage.get(STORAGE_KEYS.END_OF_PRESENTATION_PERIOD, bannerId));
    }

    /**
     * Sets end of comeback period date for banner
     *
     * @param {number} bannerId
     * @param {Date} date
     * @private
     */
    function _setEndComebackPeriod(bannerId, date) {
        return Storage.setKey(STORAGE_KEYS.END_OF_COMEBACK_PERIOD, bannerId, date.toJSON());
    }

    /**
     * Get end of comeback period date
     *
     * @param {number} bannerId
     */
    function _getEndComebackPeriod(bannerId) {
        return new Date(Storage.get(STORAGE_KEYS.END_OF_COMEBACK_PERIOD, bannerId));
    }

    /**
     * Checks if dates rounded by seconds are equal
     *
     * @param {Date} date1
     * @param {Date} date2
     * @return {boolean}
     * @private
     */
    function _areEqualDates(date1, date2) {
        const date1Timestamp = Math.floor(date1.getTime() / 1000);
        const date2Timestamp = Math.floor(date2.getTime() / 1000);
        return date1Timestamp === date2Timestamp;
    }

};