/**
 * Location model
 *
 * @param {object} origin
 * @constructor
 */
BannerApp.Location = function(origin) {
    const _this = this;

    _this.id = null;
    _this.polyline = [];

    // public methods
    this.isInside = isInside;

    _constructor(origin);

    /**
     * Constructor
     *
     * @param {object} origin
     * @private
     */
    function _constructor(origin) {
        _this.id = origin.id;
        _this.polyline = origin.polyline.split(';')
            .map(point => point.split(',').map(coordinate => parseFloat(coordinate)));
    }

    /**
     * Checks if coordinates are inside location
     *
     * @param {array} coordinates
     */
    function isInside(coordinates) {
        const [y, x] = coordinates;
        let inside = false;
        for (let i = 0, j = _this.polyline.length - 1; i < _this.polyline.length; i++) {
            const [y1, x1] = _this.polyline[i];
            const [y2, x2] = _this.polyline[j];

            if ((y1 > y) !== (y2 > y)) {
                const intersectPoint = (x2 - x1) * (y - y1) / (y2 - y1) + x1;
                if (x < intersectPoint) {
                    inside = !inside;
                }
            }
            j = i;
        }
        return inside;
    }
};