/**
 * Debugger for the app
 *
 * @param {object} options
 * @constructor
 */
BannerApp.Debug = function(options) {
    this.log = log;
    this.error = error;

    /**
     * Calls console log with data if debug mode is enabled
     *
     * @param {*} data
     */
    function log(data) {
        options.debug && console.log(data);
    }

    /**
     * Calls console error with data if debug mode is enabled
     *
     * @param {*} data
     */
    function error(data) {
        options.debug && console.error(data);
    }
};