/**
 * UserFilters model
 *
 * @param {string} userFiltersString
 * @param {object} userLocations
 * @param {{name: string, value: string}[]} externalFilterParams
 * @param {BannerApp.Consts} CONSTANT
 * @constructor
 */
BannerApp.UserFilters = function(userFiltersString, userLocations, externalFilterParams, CONSTANT) {
    const _this = this;

    const AUDIENCE_FILTER_TYPES = CONSTANT.AUDIENCE_FILTER_TYPES;

    // public properties
    _this.filters = [];
    _this.locations = {};

    /**
     * @type {{name: string, value: string}[]}
     */
    _this.externalFilterParams = [];

    // public methods
    this.fitsCampaignAudience = fitsCampaignAudience;
    this.setUserLocations = setUserLocations;

    _constructor(userFiltersString, userLocations, externalFilterParams);

    /**
     *
     * @param {string} userFiltersString - encoded string
     * @param {object} userLocations {0: {}, 1: {}, 2: {}, 3: {}}
     * @param {{name: string, value: string}[]} externalFilterParams - key/value pairs
     * @private
     */
    function _constructor(userFiltersString, userLocations, externalFilterParams) {
        _this.filters = _decodeUserFiltersString(userFiltersString);
        _this.locations = userLocations || {};
        _this.externalFilterParams = externalFilterParams || [];
    }

    /**
     *
     * @param {object} locations {0: {}, 1: {}, 2: {}, 3: {}}
     */
    function setUserLocations(locations) {
        _this.locations = locations;
    }

    /**
     * Decode user filters string
     *
     * @param {string} userFiltersString - encoded users filters
     * @return {array}
     * @private
     */
    function _decodeUserFiltersString(userFiltersString) {
        return userFiltersString.split('|').map(filterString =>
            filterString.split(',')
        )
    }

    /**
     * Checks if user filter fits campaign audience
     *
     * @param {BannerApp.Audience} campaignAudience
     * @return {boolean}
     */
    function fitsCampaignAudience(campaignAudience) {
        return campaignAudience.filters.every(audienceFilter => {
            if (AUDIENCE_FILTER_TYPES.FEEDBACK.indexOf(audienceFilter.type) !== -1) {
                return _checkFeedbackValidity(audienceFilter);
            } else if(audienceFilter.type === AUDIENCE_FILTER_TYPES.RATING) {
                return _checkRatingValidity(audienceFilter);
            } else if(audienceFilter.type === AUDIENCE_FILTER_TYPES.GEOFENCES) {
                return _checkGeofenceValidity(_this.locations, audienceFilter);
            } else if(audienceFilter.type === AUDIENCE_FILTER_TYPES.EXT_PARAMETERS) {
                return _checkExternalParamsValidity(_this.externalFilterParams, audienceFilter);
            } else {
                return false;
            }
        });
    }

    /**
     * Checks if user filters fits feedback segments
     *
     * @param {object} audienceFilter
     * @return {boolean}
     * @private
     */
    function _checkFeedbackValidity(audienceFilter) {
        return audienceFilter.values.some(filterValue => {
            const filter = filterValue.toString();
            return _this.filters.some(userFilters =>
                userFilters.indexOf(filter) !== -1
            );
        });
    }

    /**
     * Checks if user filters fits rating segments
     *
     * @param {object} audienceFilter
     * @return {boolean}
     * @private
     */
    function _checkRatingValidity(audienceFilter) {
        const starsData = audienceFilter.audienceStars;
        const ratingIds = Object.keys(audienceFilter.audienceStars);
        return ratingIds.every(id => {
            return starsData[id].some(starsCount => {
                const starsEncoded = `${id}:${starsCount}`;
                return _this.filters.some(userFilters =>
                    userFilters.indexOf(starsEncoded) !== -1
                );
            });
        });
    }

    /**
     * Checks if user locations fit geofence segment
     *
     * @param {BannerOptionsLocations} userLocations
     * @param {BannerAudienceFilter} audienceFilter
     * @return {boolean}
     * @private
     */
    function _checkGeofenceValidity(userLocations, audienceFilter) {
        return audienceFilter.locationTypes.some(type => {
            /**
             * List of location types, defined in options.locations
             * @type {number[]} - 0: current, 1: home, etc.
             */
            const userLocationTypes = Object.keys(userLocations).map(locationType => parseInt(locationType));
            const ROUTES = 5;

            // If same "audience filter location type" and "options.locations type" are defined,
            // check if the point is inside or outside of the filter's area
            if (userLocationTypes.indexOf(type) !== -1) {
                switch (type) {
                    case ROUTES: // if route crosses the Geofence area
                        const anyRouteCrossTheGeofence = audienceFilter.locations.some(location =>
                            userLocations[ROUTES].some(route => {
                                return route.some(point => {
                                    return location.isInside([point.lat, point.lng])
                                })
                            })
                        );
                        return !audienceFilter.outsideOf ? anyRouteCrossTheGeofence : !anyRouteCrossTheGeofence;
                    default: // current, home, etc.
                        if (!audienceFilter.outsideOf) {
                            return audienceFilter.locations.some(location =>
                                location.isInside([userLocations[type].lat, userLocations[type].lng])
                            );
                        } else {
                            return audienceFilter.locations.every(location =>
                                !location.isInside([userLocations[type].lat, userLocations[type].lng])
                            );
                        }
                }
            }
            return false;
        });
    }

    /**
     * @param {{name: *, value: *}[]} externalParams
     * @param {{type: number, key: *, values: *[]}} audienceFilter
     * @private
     */
    function _checkExternalParamsValidity(externalParams, audienceFilter) {
        return audienceFilter.values.some(value => {
            return externalParams.find(param => {
                return param.name === audienceFilter.key && param.value === value;
            })
        })
    }
};