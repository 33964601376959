/**
 * Audience model
 *
 * @param {string} audiencesString
 * @param {BannerApp.Consts} CONSTANT
 * @constructor
 */
BannerApp.Audience = function(audiencesString, CONSTANT) {
    const _this = this;

    const AUDIENCE_FILTER_TYPES = CONSTANT.AUDIENCE_FILTER_TYPES;

    _this.filters = [];

    // public methods
    _this.getGeofencesList = getGeofencesList;
    _this.setLocations = setLocations;

    _constructor(audiencesString);

    function _constructor(audiencesString) {
        const audiences = audiencesString ? audiencesString.split('|') : [];
        _this.filters = audiences.map(_decodeAudienceString);
    }

    /**
     * Set all audience locations
     *
     * @param {object} locations - object with locations (like associated array)
     */
    function setLocations(locations) {
         _this.filters
            .forEach(filter => {
                if (filter.type === CONSTANT.AUDIENCE_FILTER_TYPES.GEOFENCES) {
                    filter.locations = [];
                    filter.values.forEach(item => {
                        filter.locations.push(locations[item.geofenceId]);
                    });
                }
            });
    }

    /**
     * Extract all geofence ids from audience object (maybe it can be refactored somehow)
     *
     * @return {Array} - [56, 57, 58]
     * @private
     */
    function getGeofencesList() {
        const geofences = [];
        _this.filters
            .filter(filter => filter.type === CONSTANT.AUDIENCE_FILTER_TYPES.GEOFENCES)
            .forEach(filter => {
                filter.values.forEach(item => geofences.push(item.geofenceId))
            });
        return geofences;
    }

    /**
     * Decodes audience string
     *
     * @param {string} audienceString
     * @return {object}
     * @private
     */
    function _decodeAudienceString(audienceString) {
        const audienceData = {};

        audienceData.type = _getAudienceType(audienceString);
        const encodedAudienceValues = audienceString.split(',');

        if (AUDIENCE_FILTER_TYPES.FEEDBACK.indexOf(audienceData.type) !== -1) {
            audienceData.values = _decodeFeedbackAudience(encodedAudienceValues);
        }
        else if (audienceData.type === AUDIENCE_FILTER_TYPES.RATING) {
            audienceData.audienceStars = _decodeRatingAudience(encodedAudienceValues);
        }
        else if (audienceData.type === AUDIENCE_FILTER_TYPES.GEOFENCES) {
            Object.assign(audienceData, _decodeGeofenceAudience(encodedAudienceValues));
        }
        else if (audienceData.type === AUDIENCE_FILTER_TYPES.EXT_PARAMETERS) {
            Object.assign(audienceData, _decodeExternalParametersAudience(audienceString));
        }
        return audienceData;
    }

    function _getAudienceType(audienceString) {
        return Number(audienceString.split(':')[0]);
    }

    /**
     * Feedback audience decoder
     *
     * @param {array} encodedAudienceValues
     * @return {array}
     * @private
     */
    function _decodeFeedbackAudience(encodedAudienceValues) {
        const VALUE_INDEX = 1;
        return encodedAudienceValues.map(encodedValue => {
            return Number(encodedValue.split(':')[VALUE_INDEX]);
        });
    }

    /**
     * Rating audience decoder
     *
     * @param {array} encodedAudienceValues
     * @private
     */
    function _decodeRatingAudience(encodedAudienceValues) {
        const FEEDBACK_ID_INDEX = 1;
        const STARS_VALUES_INDEX = 2;
        const audienceStars = {};
        encodedAudienceValues.forEach(encodedValue => {
            const encodedStarsData = encodedValue.split(':');
            const feedbackId = Number(encodedStarsData[FEEDBACK_ID_INDEX]);
            audienceStars[feedbackId] = encodedStarsData[STARS_VALUES_INDEX]
                .split(';')
                .map(value => Number(value));
        });
        return audienceStars;
    }

    /**
     * @typedef {object} BannerAudienceFilter
     * @property {int[]} locationTypes - 0: current, 1: home, 2: work, 3: route destination, 4: monitor departure, 5: geo segmentation
     * @property {BannerApp.Location[]} locations - polygons
     * @property {boolean} outsideOf
     * @property {int} type - type of filter (eg. 4 is GEO)
     * @property {BannerAudienceFilterValue[]} values
     */

    /**
     * @typedef {object} BannerAudienceFilterValue
     * @property {number} audienceValue
     * @property {number} geofenceId
     */

    /**
     * Geofence audience decoder
     *
     * @param {array} encodedAudienceValues
     * @return {object}
     * @private
     */
    function _decodeGeofenceAudience(encodedAudienceValues) {
        const AUDIENCE_VALUE_INDEX = 1;
        const GID_ID_INDEX = 2;
        const LOCATION_TYPES_INDEX = 3;
        const LOCATION_OUTSIDE_INDEX = 4;

        const encodedAudienceData = encodedAudienceValues[0].split(':');

        return {
            locationTypes: encodedAudienceData[LOCATION_TYPES_INDEX]
                .split(';')
                .map(value => Number(value)),
            outsideOf: !!Number(encodedAudienceData[LOCATION_OUTSIDE_INDEX]),
            values: encodedAudienceValues.map(encodedAudienceValue => {
                const encodedValue = encodedAudienceValue.split(':');
                return {
                    audienceValue: Number(encodedValue[AUDIENCE_VALUE_INDEX]),
                    geofenceId: Number(encodedValue[GID_ID_INDEX]),
                };
            }),
            locations: [],
        };
    }

    /**
     * @param {string} audienceString
     * @return {{values: *, key: *}}
     * @private
     */
    function _decodeExternalParametersAudience(audienceString) {
        const INDEX_KEY = 1;
        const INDEX_VALUES = 2;
        const array = audienceString.split(':');
        return {
            key: array[INDEX_KEY],
            values: array[INDEX_VALUES].split(';'),
        }
    }
};