/**
 * Callback after the library have created a banner Node
 *
 * @callback bannerIframeCallback
 * @param {HTMLElement} bannerNode
 */

/**
 * BannerApp init class
 *
 * @param {object} options
 * @param {bannerIframeCallback} callback
 * @return {BannerApp.Banner}
 * @constructor
 */
const BannerApp = function(options, callback) {
    const CONSTANT            = new BannerApp.Consts();
    const Debug               = new BannerApp.Debug(options);
    const UtilitiesService    = new BannerApp.UtilitiesService();
    const domainName          = UtilitiesService.getDomainName(CONSTANT.LIBRARY_NAME_PATTERN);
    const Storage             = new BannerApp.Storage(CONSTANT.LOCAL_STORAGE_NAME);
    const ElementsFactory     = new BannerApp.ElementsFactory(domainName, CONSTANT);
    const PresentationChecker = new BannerApp.PresentationChecker(Storage);
    const User                = new BannerApp.User(Storage);
    const RequestService      = new BannerApp.RequestService(domainName);
    const ModelsFactory       = new BannerApp.ModelsFactory(CONSTANT, Debug);
    const BannerFactory       = new BannerApp.BannerFactory(domainName, ModelsFactory);

    const banner = new BannerApp.Banner(options, domainName, callback, ElementsFactory, Storage, BannerFactory,
        PresentationChecker, User, RequestService, UtilitiesService, ModelsFactory, Debug);

    /**
     * Handling feedback send action
     */
    window.addEventListener('message', event => {
        if (event.origin !== domainName) {
            return;
        }
        const bannerId = event.data.bannerId;
        if (banner.currentBannerId() === bannerId) {
            banner.dismiss();
            const popup = document.querySelector('.' + CONSTANT.POPUP_WRAPPER_CLASS_NAME);
            popup && popup.parentNode.removeChild(popup);
        }
        banner.updateUserFilters();
    });

    return banner;
};