/**
 * Request service
 *
 * @param {string} domainName - a domain name with library resources
 * @constructor
 */

BannerApp.RequestService = function (domainName) {

    // public methods
    this.getBannersList = getBannersList;
    this.getBannerObjects = getBannerObjects;
    this.getUserFilters = getUserFilters;
    this.getLocations = getLocations;

    /**
     * Get banners list according to page and position
     *
     * @param page
     * @param position
     * @param apiKey
     * @return {Promise<string>}
     */
    function getBannersList(page, position, apiKey = '') {
        const url = `${domainName}/share/banners/get_active_list/${page}/${position}${apiKey && '\\' + apiKey}`;
        return _getRequest(url);
    }

    /**
     * Get banner objects
     *
     * @param {array} bannersList - array of banner external ids
     * @public
     */
    function getBannerObjects(bannersList) {
        return _getRequest(domainName + '/share/banners/get_banners_data/' + bannersList.join(','));
    }

    /**
     * Get user filters string by GUID
     *
     * @param {string} userId
     * @public
     */
    function getUserFilters(userId) {
        return _getRequest(domainName + '/share/banners/get_user_filters/' + userId);
    }

    /**
     * Returns
     * @param {array} geofenceIds
     */
    function getLocations(geofenceIds) {
        return _getRequest(domainName + '/share/banners/get_location_coords/' + geofenceIds.join(','));
    }

    /**
     * Gets data from API
     * @param url
     * @return {Promise<string>}
     * @private
     */
    function _getRequest(url) {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open('GET', url);
            xhr.onload = function() {
                if (this.status !== 200) {
                    reject({
                        status: this.status,
                        statusText: xhr.statusText
                    })
                } else {
                    resolve(this.responseText);
                }
            };
            xhr.onerror = function () {
                reject({
                    status: this.status,
                    statusText: xhr.statusText
                });
            };
            xhr.send();
        })
    }
}