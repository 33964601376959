/**
 * Models Factory
 *
 * @param {BannerApp.Consts} CONSTANT
 * @param {BannerApp.Debug} Debug
 * @constructor
 */
BannerApp.ModelsFactory = function(CONSTANT, Debug) {

    this.campaign = campaign;
    this.audience = audience;
    this.userFilters = userFilters;

    this.locations = locations;
    this.location = location;

    /**
     * Campaign factory
     *
     * @param {object} origin
     * @return {BannerApp.Campaign}
     */
    function campaign(origin) {
        const campaign = new BannerApp.Campaign(origin, Debug);
        campaign.setAudience(
            audience(
                campaign.getAudienceString()
            ));
        return campaign;
    }

    /**
     * Audience factory
     *
     * @param {string} string - encoded string
     * @return {BannerApp.Audience}
     */
    function audience(string) {
        return new BannerApp.Audience(string, CONSTANT);
    }

    /**
     * User Filter factory
     *
     * @param {string} filtersString - encoded string
     * @param {object} userLocations - {0: {...}, 1: {...}, 2: {...}, 3: {...}}
     * @param {{name: string, value: string}[]} externalFilterParams
     * @return {BannerApp.UserFilters}
     */
    function userFilters(filtersString, userLocations, externalFilterParams) {
        return new BannerApp.UserFilters(filtersString, userLocations, externalFilterParams, CONSTANT);
    }

    /**
     * Location factory
     *
     * @param {object} origin
     * @return {BannerApp.Location}
     */
    function location(origin) {
        return new BannerApp.Location(origin);
    }

    /**
     * Locations factory (associated array)
     *
     * @param {array} originArray
     */
    function locations(originArray) {
        const locations = {};
        originArray.forEach(origin => {
            locations[origin.id] = new BannerApp.Location(origin);
        });
        return locations;
    }
};