/**
 * Local storage module
 *
 * @param {string} name - local storage property name
 * @constructor
 */
BannerApp.Storage = function(name) {

    /**
     * Public methods
     */
    this.get = get;
    this.set = set;
    this.setKey = setKey;
    this.push = push;
    this.unshift = unshift;
    this.includes = includes;

    _constructor();

    /**
     * Constructor
     */
    function _constructor() {
        if (!localStorage[name]) {
            localStorage[name] = '{}';
        }
    }

    /**
     * Set local storage property in section
     *
     * @param {string} section
     * @param {*} value
     * @return {*}
     */
    function set(section, value) {
        const STORAGE = _getStorage();
        STORAGE[section] = value;
        _saveStorage(STORAGE);
        return STORAGE[section];
    }

    /**
     * Set local storage property in section
     *
     * @param {string} section
     * @param {number|string} key
     * @param {*} value
     */
    function setKey(section, key, value) {
        const STORAGE = _getStorage();
        if (!STORAGE[section]) {
            STORAGE[section] = {};
        }
        STORAGE[section][key] = value;
        _saveStorage(STORAGE);
        return value;
    }

    /**
     * Get local storage property in section
     *
     * @param {string} section
     * @param {string|bool} key
     * @return {*}
     */
    function get(section, key = null) {
        const STORAGE = _getStorage();
        if (key && !STORAGE[section]) {
            return undefined;
        }
        return key ? STORAGE[section][key] : STORAGE[section];
    }

    /**
     * Push value to local storage section
     *
     * @param {string} section
     * @param {*} value
     */
    function push(section, value) {
        const STORAGE = _getStorage();
        if (!STORAGE[section]) {
            STORAGE[section] = [];
        }
        STORAGE[section].push(value);
        _saveStorage(STORAGE);
    }

    /**
     * Push value to start into local storage section
     *
     * @param {string} section
     * @param {*} value
     */
    function unshift(section, value) {
        const STORAGE = _getStorage();
        if (!STORAGE[section]) {
            STORAGE[section] = [];
        }
        STORAGE[section].unshift(value);
        _saveStorage(STORAGE);
    }

    /**
     * Check if local storage section includes a value
     *
     * @param {string} section
     * @param {*} value
     * @return {boolean}
     */
    function includes(section, value) {
        const STORAGE = _getStorage();
        if (!STORAGE[section]) {
            return false;
        }
        return STORAGE[section].includes(value);
    }

    /**
     * Update local storage with object
     *
     * @param STORAGE
     * @private
     */
    function _saveStorage(STORAGE) {
        localStorage[name] = JSON.stringify(STORAGE);
    }

    /**
     * Get storage
     *
     * @return {object}
     * @private
     */
    function _getStorage() {
        return JSON.parse(localStorage[name]);
    }
};