/**
 * User model
 *
 * @param {BannerApp.Storage} Storage - local storage
 * @constructor
 */
BannerApp.User = function(Storage) {

    const USER_STORAGE_KEY = 'user_id';

    this.getUserId = getUserId;

    _constructor();

    /**
     * Constructor
     */
    function _constructor() {
        if (!getUserId()) {
            _setUserId(_generateGUID());
        }
    }

    /**
     * Returns user id from local storage
     *
     * @return {string}
     */
    function getUserId() {
        return Storage.get(USER_STORAGE_KEY);
    }

    /**
     * Sets user id to local storage
     *
     * @param userId
     * @return {*}
     * @private
     */
    function _setUserId(userId) {
        return Storage.set(USER_STORAGE_KEY, userId);
    }

    /**
     * Generate GUID
     * @returns {string} GUID
     * @private
     */
    function _generateGUID() {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    }
};