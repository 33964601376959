 /**
  * BannerQueue class
  *
  * @param {object} options
  * @param {string} domainName
  * @param {function} bannerCallback
  * @param {BannerApp.ElementsFactory} ElementsFactory
  * @param {BannerApp.Storage} Storage
  * @param {BannerApp.BannerFactory} BannerFactory
  * @param {BannerApp.PresentationChecker} PresentationChecker
  * @param {BannerApp.User} User
  * @param {BannerApp.RequestService} RequestService
  * @param {BannerApp.UtilitiesService} UtilitiesService
  * @param {BannerApp.ModelsFactory} ModelsFactory
  * @param {BannerApp.Debug} Debug
  * @constructor
  */
BannerApp.Banner = function(options, domainName, bannerCallback, ElementsFactory, Storage, BannerFactory,
                            PresentationChecker, User, RequestService, UtilitiesService, ModelsFactory, Debug) {
    const _this = this;

    /**
     * Constants
     */
    const BANNER_CLICK_ACTION_OPEN_INFOTAINMENT = 1;
    const BANNER_CLICK_ACTION_EXTERNAL_LINK = 4;
    const BANNER_TYPE_POP_OVER = 3;

    /**
     * Queue name
     *
     * @type {string}
     */
    const queueName = `queue_${options.page}_${options.position}`;

    /**
     * Banner objects
     *
     * @type {object}
     */
    let bannerObjects;

    /**
     * Current banner data object
     *
     * @type {object}
     */
    let currentBanner;

    /**
     * Current banner HTML element
     *
     * @type {HTMLElement}
     */
    let bannerWrapper;

    /**
     * Banners queue - array of banner ids, eg. [1, 223, 345]
     *
     * @type {Array}
     */
    let bannerQueue;

    /**
     * Banner Id
     *
     * @type {int}
     */
    let bannerId;

    /**
     * UserFilters
     *
     * @type {BannerApp.UserFilters}
     */
    let userFilters;

    _constructor(options);


    /**
     * Public methods
     */
    this.click   = click;
    this.next    = showNextBanner;
    this.dismiss = dismiss;
    this.remove  = remove;
    this.resize  = resize;
    this.currentBannerId  = getCurrentBannerId;
    this.updateUserLocations  = updateUserLocations;
    this.updateUserFilters    = updateUserFilters;

    /**
     * @typedef {Object} BannerOptions
     *
     * @property {string} apiKey
     * @property {string} page
     * @property {string} position
     * @property {string} languageCode
     * @property {function} onReady
     * @property {callback} onDismiss
     * @property {number|string} width
     * @property {number|string} height
     * @property {boolean} autoHeight
     * @property {BannerOptionsLocations} locations
     * @property {boolean} debug
     * @property {boolean} isTestApp
     */

    /**
     * @typedef {Object} BannerOptionsLocations
     *
     * @property {LatLng} 0 - current location
     * @property {LatLng} 1 - home location
     * @property {LatLng} 2 - work location
     * @property {LatLng} 6 - Route - Departure
     * @property {LatLng} 3 - Route - Destination
     * @property {LatLng} 4 - Monitor - Departure
     * @property {LatLng[][]} 5 - Route - Route
     */

    /**
     * @typedef {Object} LatLng
     *
     * @property {number} lat
     * @property {number} lng
     */

    /**
     * Banner constructor
     *
     * @param {BannerOptions} options
     * @private
     */
    function _constructor(options) {
        _setCss();

        if (!options.apiKey) {
            console.error('ApiKey is missing! Please set "options.apiKey" property during initialization');
        }

        if (typeof options.isTestApp === 'undefined') {
            options.isTestApp = true;
        }

        Promise.all([
            RequestService.getBannersList(options.page, options.position, options.apiKey),
            updateUserFilters()
        ])
        .then(([response]) => {
            const bannersList = JSON.parse(response).map(item => Number(item));
            return _loadBanners(bannersList);
        })
        .then(() => {
            if (options.onReady) {
                options.onReady();
            }
        })
        .then(() => showNextBanner())
        .catch(console.error.bind(console));
    }

    /**
     * Show next banner
     */
    function showNextBanner() {
        if (bannerWrapper) {
            bannerWrapper.remove();
        }

        if (!bannerQueue) {
            return false;
        }

        // Loop banner queue to find first banner allowed to show
        for (let $i = 0; $i < bannerQueue.length; $i++) {

            bannerId = _moveQueue();

            if (!bannerId) {
                Debug.error(`The banners queue returned empty banner id`);
                continue;
            }

            if (!bannerObjects[bannerId]) {
                Debug.error(`Banner #${bannerId} is skipped (no object in CMS)`);
                continue;
            }

            currentBanner = bannerObjects[bannerId];

            Debug.log(`------------------ Banner ${currentBanner.debugName} ------------------`);

            if (!currentBanner.infotainment.data) {
                Debug.error(`Banner ${currentBanner.debugName} is skipped (infotainment #` +
                    `${currentBanner.infotainment.externalId} is missing in CMS)`);
                continue;
            }

            Debug.log(`Banner ${currentBanner.debugName}: checking campaigns conditions`);

            // Check if some of banner's campaigns are passing through active conditions
            if (!currentBanner.campaigns.some(campaign => campaign.canBeShown(userFilters, options.isTestApp))) {
                Debug.error(`Banner ${currentBanner.debugName}: can not be shown by campaign conditions (date, test mode or audience)`);
                continue;
            }

            Debug.log(`Banner ${currentBanner.debugName}: one of campaigns is ACTIVE`);

            PresentationChecker.updateCounters(currentBanner);
            PresentationChecker.updateState(currentBanner);

            if (PresentationChecker.inPresentationState(currentBanner)) {
                _makeBannerElement(currentBanner, bannerIframe => {
                    if (currentBanner.type === BANNER_TYPE_POP_OVER) {
                        bannerWrapper = _makeBannerPopup(bannerIframe);
                        document.body.appendChild(bannerWrapper);
                        setTimeout(() => {
                            bannerWrapper.focus();
                        }, 500);
                    } else {
                        bannerWrapper = bannerIframe;
                        bannerCallback(bannerWrapper);
                    }
                });
                return;
            } else {
                Debug.log(`Banner #${bannerId} is skipped (not in presentation state)`);
            }
        }
        bannerCallback(null);
    }

    /**
     * Returns current banner id
     *
     * @return {int|boolean}
     */
    function getCurrentBannerId() {
        return currentBanner ? currentBanner.externalId : false;
    }

    /**
     * Click action
     */
    function click() {
        /**
         * In case "open infotainment" - create iframe with infotainment-app inside,
         * put the iframe into popup and append the popup to document body
         */
        if (currentBanner.clickAction.caseId === BANNER_CLICK_ACTION_OPEN_INFOTAINMENT)
        {
            document.body.appendChild(
                _makeInfotainmentPopup(bannerId, options.languageCode, User.getUserId()));
        }

        // In case "external link" - create new window and focus it
        else if (currentBanner.clickAction.caseId === BANNER_CLICK_ACTION_EXTERNAL_LINK)
        {
            const win = window.open(currentBanner.clickAction.param, '_blank');
            win ? win.focus() : alert('Please allow popups for this website');
        }

        PresentationChecker.makeClicked(bannerId);

        // Remove pop-over banner on click action
        if (currentBanner.type === BANNER_TYPE_POP_OVER) {
            remove();
        }
    }

    /**
     * Remove banner from screen
     */
    function remove() {
        if (bannerWrapper && bannerWrapper.parentNode) {
            bannerWrapper.parentNode.removeChild(bannerWrapper);
        }
    }

    /**
     * Dismiss banner (do not show it anymore)
     */
    function dismiss() {
        remove();
        PresentationChecker.makeDismissed(bannerId);
        options.onDismiss && options.onDismiss();
    }

    /**
     * Resize banner
     *
     * @param {int} width
     * @param {int} height
     */
    function resize(width, height) {
        options.width = width;
        options.height = height;
        if (bannerWrapper) {
            bannerWrapper.setAttribute('width', options.width.toString());
            bannerWrapper.setAttribute('height', options.height.toString());
        }
    }

    /**
     * Updates user locations
     * TODO: may crash when user filters were not loaded
     *
     * @param {BannerOptionsLocations} locations
     */
    function updateUserLocations(locations) {
        options.locations = locations;
        userFilters && userFilters.setUserLocations(locations);
        return _this;
    }

    /**
     * Get banner element
     *
     * @param {object} bannerData
     * @param {function} callback
     * @param {number} opacity
     * @private
     */
    function _makeBannerElement(bannerData, callback, opacity = 1) {
        const bannerDocument = BannerFactory.makeBannerDocument(bannerData);
        const isPopover = bannerData.type === BANNER_TYPE_POP_OVER;

        /**
         * Create iframe wrapper for banner,
         * then put banner document inside and create event listeners for banner's inner elements
         */
        const bannerIframe = ElementsFactory.iframe({
            url: 'about:blank',
            document: bannerDocument,
            class: isPopover ? 'trm-banner-popover' : 'trm-banner-block',
            width: !isPopover && options.width,
            height: !isPopover && options.height,
            onload: iframe => {
                //Add event listener to banner for click action
                const bannerElement = iframe.contentWindow.document.querySelector('.banner');
                bannerElement.addEventListener('click', event => {
                    const isTargetCloseBtn = event.target.classList.contains('banner-close');
                    return !isTargetCloseBtn && click();
                });

                // Add event listener to banner close button (for dismissing the banner)
                const closeBtn = bannerElement.querySelector('.banner-close');
                if (closeBtn) {
                    closeBtn.addEventListener('click', () => dismiss());
                }
            },
            onRendered: bannerData.imageOnly && options.autoHeight
                ? iframeSize => {
                    UtilitiesService.getImageSize(bannerData.image)
                        .then(imageSize => {
                            const scale = iframeSize.width / imageSize.width;
                            bannerIframe.style.height = imageSize.height * scale + 'px';
                        });
                }
                : null,
        });

        callback(bannerIframe);
    }

    /**
     * Returns infotainment popup element
     *
     * @param {int|string} bannerId
     * @param {string} languageCode
     * @param {string} userId
     * @return {HTMLElement}
     * @private
     */
    function _makeInfotainmentPopup(bannerId, languageCode, userId) {
        return ElementsFactory.popup(
            ElementsFactory.iframe({
                url: `${domainName}/infotainment-app/#!/${bannerId}/${languageCode}/${userId}`,
                class: 'popup-iframe',
                width: '100%',
                height: '100%',
            }), {
                closeBtn: true
            });
    }

    /**
     * Returns banner popup element
     *
     * @param {HTMLElement} bannerElement
     * @return {HTMLElement}
     * @private
     */
    function _makeBannerPopup(bannerElement) {
        return ElementsFactory.popup(bannerElement, {
            closeBtn: true,
            dismissUrl: {
                text: 'Do not show me this banner anymore',
                click: dismiss
            }
        });
    }

    /**
     * Filter dismissed banners, clean old queue and update the queue with new banners
     *
     * @param {array} bannersList
     * @return {array}
     * @private
     */
    function _makeQueue(bannersList) {
        const oldQueue = Storage.get(queueName);

        // If queue exists - clean it from deleted banners and push new banners to the start
        if (oldQueue && oldQueue.length) {
            const cleanedQueue = oldQueue.filter(bannerId => bannersList.includes(bannerId));
            Storage.set(queueName, cleanedQueue);

            bannersList.map(bannerId => {
                if (!Storage.includes(queueName, bannerId)) {
                    Storage.unshift(queueName, bannerId);
                }
            });
        } else {
            Storage.set(queueName, bannersList);
        }

        return Storage.get(queueName);
    }

    /**
     * Return first element from queue and move it to the end
     *
     * @return {number}
     * @private
     */
    function _moveQueue() {
        const queue = Storage.get(queueName);
        const firstElement = queue.shift();
        queue.push(firstElement);
        Storage.set(queueName, queue);
        return firstElement;
    }

    /**
     * Callback on banners list load
     *
     * @param {array} bannersList
     * @return {Promise} - returns promise when banner objects are set
     * @private
     */
    function _loadBanners(bannersList) {
        bannerQueue = _makeQueue(bannersList);
        return RequestService.getBannerObjects(bannerQueue)
            .then(json => _prepareBanners(json))
            .then(banners => {
                bannerObjects = banners;
            });
    }

    /**
     * Gets user filters
     *
     * @return {Promise} - returns promise when user filters are set
     * @public
     */
    function updateUserFilters() {
        return RequestService.getUserFilters(User.getUserId())
            .then(response => {
                userFilters = ModelsFactory.userFilters(response, options.locations, options.externalFilterParams);
            });
    }

    /**
     * Callback on banner objects load
     *
     * @param {string} json
     * @private
     */
    function _prepareBanners(json) {
        const bannersOrigin = JSON.parse(json);
        const banners = {};
        Object.keys(bannersOrigin).map(id => {
            const lang = UtilitiesService.getCurrentLanguage(bannersOrigin[id].TenantLanguages, options.languageCode);
            const defaultLang = UtilitiesService.getDefaultLanguage(bannersOrigin[id].TenantLanguages);

            if (!lang || !defaultLang) {
                throw new Error('Can not get the languages list');
            }

            banners[id] = BannerFactory.makeBannerData(bannersOrigin[id], lang.id, defaultLang.id);
        });

        const geofencesList = UtilitiesService.extractGeofences(banners);

        if (geofencesList.length) {
            return RequestService.getLocations(geofencesList)
                .then(response => {
                    const locations = ModelsFactory.locations(JSON.parse(response));
                    Object.keys(banners).map(id => {
                        banners[id].campaigns.forEach(campaign => {
                            campaign.audience.setLocations(locations);
                        });
                    });
                    return banners;
                });
        } else {
            return banners;
        }
    }

    /**
     * Setting common CSS styles to body
     *
     * @private
     */
    function _setCss() {
        document.body.appendChild(ElementsFactory.style(`
            .trm-banner-block {
                border: 1px #979797 solid;
            }
        `));
    }
};