/**
 * BannerFactory class constructs banner object or banner HTML document
 *
 * @param {string} domainName
 * @param {BannerApp.ModelsFactory} ModelsFactory
 * @constructor
 */
BannerApp.BannerFactory = function(domainName, ModelsFactory) {

    /**
     * Constants
     */
    const BANNER_FORMATS = {
        1: 'compact',
        2: 'slim',
        3: 'simple',
        4: 'pop-over',
    };

    const BANNER_TYPES = {
        1: 'infotainment',
        2: 'simple',
        3: 'pop-over'
    };

    const FORMAT_SIMPLE = 3;
    const BANNER_TYPE_POPOVER = 3;

    const BANNER_REFERENCE_TYPES = {
        INFOTAINMENT: 0,
        LOYALTY_CAMPAIGN: 1
    };

    /**
     * Public methods
     */
    this.makeBannerData     = makeBannerData;
    this.makeBannerDocument = makeBannerDocument;

    /**
     * Prepare banner data for rendering
     *
     * @param {object} bannerOrigin - original banner object from API
     * @param {int|string} langId
     * @param {int|string} defaultLangId
     */
    function makeBannerData(bannerOrigin, langId, defaultLangId) {
        bannerOrigin.title = JSON.parse(bannerOrigin.title);
        bannerOrigin.message = JSON.parse(bannerOrigin.message);

        const infotainment = bannerOrigin.Infotainment;

        if (infotainment && infotainment.title) {
            infotainment.title = JSON.parse(infotainment.title);
        }

        if (infotainment && infotainment.message) {
            infotainment.message = JSON.parse(infotainment.message);
        }

        if (infotainment && infotainment.campaign_label) {
            infotainment.campaign_label = JSON.parse(infotainment.campaign_label);
        }

        if (infotainment && infotainment.description) {
            infotainment.description = JSON.parse(infotainment.description);
        }

        const bannerOriginMessage = bannerOrigin.message;
        const banner = {
            id: bannerOrigin.id,
            externalId: bannerOrigin.reference_id,
            title:      _getBannerTitle(bannerOrigin, langId) || _getBannerTitle(bannerOrigin, defaultLangId),
            text:       _getBannerText(bannerOrigin, langId) || _getBannerText(bannerOrigin, defaultLangId),
            textColor:  _getBannerTextColorStyle(bannerOriginMessage.textColor),
            image:      _getBannerImage(bannerOrigin),
            type:       bannerOriginMessage.bannerType,
            format:     bannerOriginMessage.formatType,
            imageOnly:  bannerOriginMessage.imageOnly,
            infotainment: {
                id: bannerOriginMessage.infoId,
                externalId: bannerOriginMessage.infoExternalId,
                referenceType: bannerOriginMessage.referenceType,
                data: infotainment
            },
            clickAction: {
                caseId: Number(bannerOriginMessage.clickOptions.case),
                param:  bannerOriginMessage.clickOptions.param,
            },
            presentationOptions: bannerOriginMessage.presentationOptions,
            comebackOptions: bannerOriginMessage.comebackOptions,
            classes: _getBannerClasses(bannerOriginMessage),
            styles: _getBannerInlineStyles(bannerOrigin, bannerOriginMessage),
            campaigns: bannerOrigin.Infotainment.campaigns.map(campaign => ModelsFactory.campaign(campaign)),
            created: bannerOrigin.created_at,
            modified: bannerOrigin.updated_at,
        };

        banner.debugName = `int#${banner.id} ext#${banner.externalId} "${banner.title}"`;
        return banner;
    }

    function makeBannerDocument(bannerObject) {
        let bannerHTML =
        `<!DOCTYPE html>` +
        `<html>` +
        `<head>` +
            `<meta name="viewport" content="width=device-width, initial-scale=1">` +
            `<link rel="stylesheet" href="${domainName}/banner-lib/banner.css">` +
        `</head>` +
        `<body>` +
        `<div class="banner ${bannerObject.classes}" style="${bannerObject.styles}">`;

        if (!bannerObject.imageOnly && bannerObject.image) {
            bannerHTML +=
            `<div class="banner-image" style="background-image: url(${bannerObject.image})"></div>`;
        }

        if (bannerObject.imageOnly) {
            bannerHTML +=
                `<img class="banner-image-only" alt="" src="${bannerObject.image}">`;
        }

        if (!bannerObject.imageOnly) {
            bannerHTML +=
                `<div class="banner-text">` +
                `<div class="banner-title" style="${bannerObject.textColor}">${bannerObject.title}</div>`;
        }

        if (!bannerObject.imageOnly && bannerObject.text) {
            bannerHTML +=
                `<div class="banner-message" style="${bannerObject.textColor}">${bannerObject.text}</div>`;
        }

        bannerHTML +=
            `</div>`;

        if (bannerObject.type !== BANNER_TYPE_POPOVER) {
            bannerHTML +=
            `<div class="banner-close" style="${bannerObject.textColor}" aria-label="Dismiss" role="button" tabindex="0">×</div>`;
        }

        bannerHTML +=
        `</div>` +
        `</body>` +
        `</html>`;

        return new DOMParser().parseFromString(bannerHTML, 'text/html');
    }

    /**
     * Return banner message text
     *
     * @param {object} bannerOrigin
     * @param {int} langId
     * @return {string}
     * @private
     */
    function _getBannerTitle(bannerOrigin, langId) {
        const bannerTitle = bannerOrigin.message.title[langId];
        if (bannerTitle) {
            return bannerTitle;
        }

        if (_isStandaloneBanner(bannerOrigin)) {
            return '';
        }

        // If banner has no title - return the infotainment's title
        const referenceType = bannerOrigin.message.referenceType;
        if (referenceType === BANNER_REFERENCE_TYPES.INFOTAINMENT) {
            return bannerOrigin.Infotainment.title[langId];
        } else if (referenceType === BANNER_REFERENCE_TYPES.LOYALTY_CAMPAIGN) {
            return bannerOrigin.Infotainment.campaign_label[langId];
        }
    }

    /**
     * Return banner message text
     *
     * @param {object} bannerOrigin
     * @param {int} langId
     * @return {string}
     * @private
     */
    function _getBannerText(bannerOrigin, langId) {
        const bannerMessage = bannerOrigin.message.message[langId];
        if (bannerMessage) {
            return bannerMessage;
        }

        if (_isStandaloneBanner(bannerOrigin)) {
            return '';
        }

        // If banner has no title - return the infotainment's title
        const referenceType = bannerOrigin.message.referenceType;
        if (referenceType === BANNER_REFERENCE_TYPES.INFOTAINMENT) {
            const infoMessage = bannerOrigin.Infotainment.message;
            return infoMessage.message ? infoMessage.message[langId] : infoMessage[langId];
        } else if (referenceType === BANNER_REFERENCE_TYPES.LOYALTY_CAMPAIGN) {
            return bannerOrigin.Infotainment.description[langId];
        }
    }

    /**
     * Check if banner is standalone
     *
     * @param bannerOrigin
     * @returns {boolean}
     * @private
     */
    function _isStandaloneBanner(bannerOrigin) {
        try {
            return JSON.parse(bannerOrigin.data).standaloneBanner;
        } catch (e) {
            return false;
        }
    }

    /**
     * Generate banner's text color style
     *
     * @param {string} color
     * @private
     */
    function _getBannerTextColorStyle(color) {
        return color && `color: ${color};`;
    }

    /**
     * Get banner inline styles
     *
     * @param {object} bannerOrigin
     * @param {object} bannerOriginMessage
     * @return {string}
     * @private
     */
    function _getBannerInlineStyles(bannerOrigin, bannerOriginMessage) {
        const styles = [];

        if (!bannerOriginMessage.imageOnly && bannerOriginMessage.backgroundColor) {
            styles.push(`background-color: ${bannerOriginMessage.backgroundColor};`);
        }

        if (bannerOriginMessage.formatType === FORMAT_SIMPLE) {
            const image = _getBannerImage(bannerOrigin);
            if (image) {
                styles.push(`background-image: url(${image});`);
            }
        }

        return styles.join(' ');
    }

    /**
     * Get list of classes for banner element
     *
     * @param {object} bannerOriginMessage
     * @return {string}
     * @private
     */
    function _getBannerClasses(bannerOriginMessage) {
        const classes = [
            BANNER_FORMATS[bannerOriginMessage.formatType],
            BANNER_TYPES[bannerOriginMessage.bannerType]
        ];

        if (bannerOriginMessage.imageOnly) {
            classes.push('image-only');
        }

        if (bannerOriginMessage.titleOnly) {
            classes.push('title-only');
        }

        return classes.join(' ');
    }

    /**
     * Get banner image from origin object (or get infotainment image if banner has no image)
     *
     * @param {object} bannerOrigin
     * @private
     */
    function _getBannerImage(bannerOrigin) {
        const bannerImage = bannerOrigin.image_src;
        const infotainmentImage = bannerOrigin.Infotainment && bannerOrigin.Infotainment.image_src;
        return bannerImage || infotainmentImage;
    }
};