/**
 * Utilities service
 *
 * @constructor
 */
BannerApp.UtilitiesService = function() {

    // public properties
    this.getCurrentLanguage = getCurrentLanguage;
    this.getDefaultLanguage = getDefaultLanguage;
    this.extractGeofences = extractGeofences;
    this.getDomainName = getDomainName;
    this.getImageSize = getImageSize;

    /**
     * Extract all geofences from banners
     * TODO: move it to the BannerWrapper class and rename to getGeofencesList (current BannerApp.Banner class) and make it without attributes
     *
     * @param {object} banners
     * @return {Array}
     */
    function extractGeofences(banners) {
        const geofences = [];
        Object.keys(banners).forEach(bannerId => {
            banners[bannerId].campaigns.forEach(campaign => {
                campaign.audience.getGeofencesList()
                    .forEach(geofenceId => geofences.push(geofenceId))
            })
        });
        return geofences;
    }

    /**
     * Get current language
     *
     * @param {array} langArray
     * @param {string} langCode
     * @return {object}
     * @public
     */
    function getCurrentLanguage(langArray, langCode) {
        return langArray.find(lang => lang.code.toLowerCase() === langCode.toLowerCase());
    }

    /**
     * Get default language
     *
     * @param {array} langArray
     * @return {object}
     * @public
     */
    function getDefaultLanguage(langArray) {
        const defaultLanguage = langArray[0];
        if (!defaultLanguage) {
            throw new Error('Can not choose the default language');
        }
        return defaultLanguage;
    }

    /**
     * Returns domain name of current script
     *
     * @return {string}
     * @private
     */
    function getDomainName(regexp) {
        const scripts = document.getElementsByTagName('script');
        for (let i = 0; i < scripts.length; i++) {
            const pattern = regexp;
            if ( pattern.test( scripts[i].getAttribute('src') ) ) {
                return scripts[i].getAttribute('src').replace(pattern, '')
            }
        }
    }

    /**
     * @param {string} url
     * @return {Promise<{width: number, height: number}>}
     */
    function getImageSize(url) {
        return new Promise(
            resolve => {
                const img = new Image();
                img.onload = function() {
                    resolve({
                        width: this.width,
                        height: this.height,
                    });
                };
                img.src = url;
            }
        )

    }
};