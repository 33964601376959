/**
 * App constants
 *
 * @constructor
 */
BannerApp.Consts = function() {
    /**
     * Audience filter types
     *
     * @type {object}
     */
    this.AUDIENCE_FILTER_TYPES = {
        // Feedback covers types 2 & 3 in mobile API
        RATING: 1,
        FEEDBACK: [2, 3],
        GEOFENCES: 4,
        EXT_PARAMETERS: 16,
    };

    /**
     * Library path pattern to exclude the domain name from script path
     *
     * @type {RegExp}
     */
    this.LIBRARY_NAME_PATTERN = /\/banner-lib\/banner.js/i;

    /**
     * Local storage name
     *
     * @type {string}
     */
    this.LOCAL_STORAGE_NAME = 'bannersStorage';

    /**
     * Popup wrapper class name
     *
     * @type {string}
     */
    this.POPUP_WRAPPER_CLASS_NAME = 'trm-popup-overlay';
};